import React from "react";
import '../../components/Layout/layout.css';

const TableHeader = ({ children }) => {
  return (
    
    <th className="table-row">{children}</th>

  );
};

export default TableHeader;
